import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';

@Component({
    selector: 'pm-activity-log-dialog',
    template: `
        <pro-dialog-title title="activity_log"></pro-dialog-title>
        <div mat-dialog-content>
            <pm-entity-activity-log [entityName]="data.entityName"
                                    [entityId]="data.id" (onLoad)="returnLogValue($event)"></pm-entity-activity-log>
        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `
})

export class ActivityLogDialogComponent {

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private dialogRef: MatLegacyDialogRef<ActivityLogDialogComponent>,
    ) {

    }

    returnLogValue = (value: any) => {
        this.dialogRef.close(value);
}

}
